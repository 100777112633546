import React from 'react';

import { CommentOutlined } from '@ant-design/icons';
import { IRouter } from '@routers/interface';

const routerService: IRouter = {
  path: '/service',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'services.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <CommentOutlined />,
  },
};

export default routerService;
