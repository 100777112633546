export default {
  'serviceDeskCms.name': 'Quầy dịch vụ',
  'serviceDeskCms.title': 'Quản lý quầy dịch vụ',

  'serviceDeskCms.serviceDeskCode': 'Mã quầy dịch vụ',
  'serviceDeskCms.serviceDeskName': 'Tên quầy dịch vụ ',
  'serviceDeskCms.isActive': 'Trạng thái hoạt động',
  'serviceDeskCms.service.serviceName': 'Dịch vụ sử dụng',
  'serviceDeskCms.createDateTime': 'Thời gian tạo',
  'serviceDeskCms.action': 'Hành động',

  'serviceDeskCms.create': 'Thêm quầy dịch vụ',
  'serviceDeskCms.information': 'Thông tin quầy dịch vụ',
  'serviceDeskCms.update': 'Cập nhật quầy dịch vụ',
  'serviceDeskCms.status.active': 'Hoạt động',
  'serviceDeskCms.status.notActive': 'Ngưng hoạt động',
  'serviceDeskCms.validator-code': 'Mã quầy dịch vụ không được để trống',
  'serviceDeskCms.validator-name': 'Tên quầy dịch vụ không được để trống',
  'serviceDeskCms.serviceCode': 'Dịch vụ sử dụng',
  'serviceDeskCms.internalComment': 'Mô tả',
  'serviceDeskCms.delete.content': 'Bạn có muốn xóa quầy dịch vụ này?',
  'serviceDeskCms.createdAt': 'Chọn thời gian',
  'serviceDeskCms.dateFrom': 'Từ ngày',
  'serviceDeskCms.dateTo': 'Đến ngày',
  'serviceDeskCms.filter.status': 'Trạng thái hoạt động',

  'serviceDeskCms.isActive.id': '{status, select, 1 {Hoạt động} 0 {Ngưng hoạt động} other {--} }',
  'serviceDeskCms.modal.text': 'Cách gọi số: Xin mời khách hàng có số thứ tự ... vào',
};
