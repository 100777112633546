import React from 'react';
import * as Icon from 'react-feather';

import { IRouter } from '@routers/interface';

import routerDeviceType from './pages/DeviceType/router';
import routerRoles from './pages/Roles/router';
import routerUsers from './pages/Users/router';

const routerSetting: IRouter = {
  path: null,
  activePath: 'setting',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'setting.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <Icon.Settings />,
  },
  routes: [routerDeviceType, routerRoles, routerUsers],
};

export default routerSetting;
