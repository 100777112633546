import { routerCheckCode } from '@view/Auth/CheckCode/router';
import { routerForgotPassword } from '@view/Auth/ForgotPassword/router';
import { routerLogin } from '@view/Auth/Login/router';
import { routerViewProfile } from '@view/Auth/Profiles/router';
import { routerResetPassword } from '@view/Auth/ResetPassword/router';
import routerDevice from '@view/Device/router';
import routerEvaluate from '@view/Evaluate/router';
import { routerHome } from '@view/Home/router';
import { routerPageError } from '@view/PageError/router';
import routerInformationPage from '@view/Service/component/InformationPage/router';
import routerService from '@view/Service/router';
import routerDetailServiceDesk from '@view/ServiceDesk/DetailServiceDesk/router';
import routerServiceDesk from '@view/ServiceDesk/router';
import routerServiceDeskCms from '@view/ServiceDeskCms/router';
import routerDeviceType from '@view/Setting/pages/DeviceType/router';
import routerRoles from '@view/Setting/pages/Roles/router';
import routerUsers from '@view/Setting/pages/Users/router';
import routerSetting from '@view/Setting/router';
import routerTicket from '@view/Ticket/router';

import { IRouter } from './interface';

export const privatePage: IRouter[] = [
  routerHome,
  routerViewProfile,
  routerService,
  routerInformationPage,
  routerDevice,
  routerUsers,
  routerRoles,
  routerEvaluate,
  routerServiceDeskCms,
  routerTicket,
  routerSetting,
  routerDeviceType,
  routerServiceDesk,
  routerDetailServiceDesk,
  routerPageError,
];

export const publicPage: IRouter[] = [
  routerForgotPassword,
  routerResetPassword,
  routerCheckCode,
  routerLogin,
  routerPageError,
];
