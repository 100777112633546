import React from 'react';

import { LaptopOutlined } from '@ant-design/icons';
import { IRouter } from '@routers/interface';

const routerDevice: IRouter = {
  path: '/device',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'device.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <LaptopOutlined />,
  },
};

export default routerDevice;