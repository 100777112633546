import React from 'react';
import { Layers } from 'react-feather';

import { IRouter } from '@routers/interface';

const routerTicket: IRouter = {
  path: '/ticket',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'ticket.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <Layers />,
  },
};

export default routerTicket;