export default {
  'reset.password.title': 'Reset Password',
  'reset.password.newPassword': 'A new password',
  'reset.password.confirm.newPassword': 'Confirm new password',
  'reset.password.title.error': 'Connection errors !!',
  'reset.password.notification':
    'There was an error during the connection or your link expired.Please try again or ask to resend the link to reset your password.',
  'reset.password.not.match': 'Two passwords you entered do not match!',
  'checkCode.title': 'Code check',
  'checkCode.label': 'Please enter the code received via email here.',
  'checkCode.notMatch': 'The code is not formatted',
  'reset.format.password': 'The password is incorrectly formatted',
};
