export default {
  'users.name': 'User management',
  'users.userName': 'User name',
  'users.userFullName': 'Full name',
  'users.phoneNumber': 'Phone number',
  'users.action': 'Act',
  'users.update': 'Update user',
  'users.information': 'User information',
  'users.password': 'Password',
  'users.role.roleName': 'Role',
  'users.create': 'Create user',
  'users.delete': 'Delete user',
  'user.content.delete': 'Are you sure you want to delete this user?',
  'users.citizenId': 'Indentify',
  'users.userEmail': 'Email',
  'users.birthday': 'Date of birth',
  'users.address': 'Address',
  'users.gender': 'Gender',
  'users.male': 'Male',
  'users.female': 'Female',
  'users.other': 'Other',
  'users.countryName': 'Country name',
  'users.isActive': 'Status',

  'profile.edit': 'Edit',
  'users.userType.admin': 'Management',
  'users.userType.accountant': 'Accountant',
  'users.userType.top.up': 'Recharge',
  'transaction.order': 'Purchase',
  'users.userType.order': 'Sell',
  'users.content.delete': 'Are you sure want to delete this user?',

  'users.table.username': 'User name',
  'users.table.name': 'Full name',
  'users.table.type': 'Account Type',
  'users.table.role': 'Role',
  'users.table.phoneNumber': 'Phone number',
  'users.table.email': 'Email',
  'users.table.action': 'Action',
  'users.table.type.id': '{status, select, 1 {System users} 2 {PG} other {--}}',

  'users.modal.form.username': 'User name',
  'users.modal.form.password': 'Password',
  'users.modal.form.password.placeholder': 'Please enter a password',
  'users.modal.form.confirmPassWord': 'Confirm password',
  'users.modal.form.confirmPassWord.placeholder': 'Please enter password confirmation',
  'users.modal.form.confirmPassWord.required': 'The two passwords you entered do not match',
  'users.modal.form.name': 'Full name',
  'users.modal.form.gender': 'Gender',
  'users.modal.form.gender.placeholder': 'Please enter gender',
  'users.modal.form.gender.label.1': 'Male',
  'users.modal.form.gender.label.2': 'Female',
  'users.modal.form.gender.label.3': 'Other',
  'users.modal.form.dob': 'Date of birth',
  'users.modal.form.avatar': 'Avatar',
  'users.modal.form.roleId': 'Role',
  'users.modal.form.phoneNumber': 'Phone number',
  'users.phoneNumber.notMatch': 'Invalid phone number',
  'users.modal.form.email': 'Email',
  'users.modal.form.citizenId': 'ID',
  'users.modal.form.countryName': 'Nation',
  'users.modal.form.address': 'Address',
  'users.modal.form.type': 'Account type',
  'users.modal.form.type.placeholder': 'Please enter account type',
  'users.modal.form.type.label.1': 'System users',
  'users.modal.form.type.label.2': 'PG',

  'user.userName': 'User name',
  'user.userFullName': 'Full name',
  'user.userEmail': 'Email',
  'user.userPhone': 'Phone number',
  'user.userAvatar': 'Avatar',
  'user.userPassword': 'Password',
  'users.userPhone': 'Phone number',

  'user.isActive': 'Status',
  'user.status.active': 'Active',
  'user.status.notActive': 'Not active',
  'user.delete': 'Do you want to delete this user?',

  'user.validator-userName': 'The username is not allowed to leave the blank',
  'user.validator-userFullName': 'Full name is not left empty',

  'users.isActive.id': '{status, select, 1 {Active} 0 {Not active} other {--} }',
  'users.validate-password': 'The password is incorrectly formatted',
  'users.validate-phone': 'Phone number improperly formatted',
};
