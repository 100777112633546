export default {
  'services.name': 'Dịch vụ',
  'services.servicesManagement': 'Quản lý dịch vụ',
  'services.serviceCode': 'Mã dịch vụ',
  'services.serviceName': 'Tên dịch vụ',
  'services.desks': 'Quầy dịch vụ',
  'services.createDateTime': 'Thời gian tạo',
  'services.isActive': 'Trạng thái hoạt động',
  'services.isActive.id':
    '{status, select, 1 {Đang hoạt động} 2 {Dừng hoạt động} other {Không xác định}}',
  'services.action': 'Hành động',
  'services.dateFrom': 'Từ ngày',
  'services.dateTo': 'Đến ngày',
  'services.internalComment': 'Mô tả',
  'services.create': 'Thêm dịch vụ',
  'services.update': 'Cập nhật dịch vụ',
  'services.information': 'Thông tin dịch vụ',
  'services.addTime': 'Thêm khoảng thời gian',
  'services.time': 'Thời gian áp dụng',
  'services.requiredTime': 'Vui lòng chọn giờ',
  'services.applyBy': 'Áp dụng theo',
  'services.dayOfWeek': 'Ngày trong tuần',
  'services.dateOfMonth': 'Ngày trong tháng',
  'services.monday': 'Thứ hai',
  'services.tuesday': 'Thứ ba',
  'services.wednesday': 'Thứ tư',
  'services.thursday': 'Thứ năm',
  'services.friday': 'Thứ sáu',
  'services.saturday': 'Thứ bảy',
  'services.sunday': 'Chủ nhật',
  'services.requiredDay': 'Vui lòng chọn ngày',
  'services.serviceThumbnail': 'Hình ảnh',
  'services.deleteContent': 'Bạn có muốn xóa dịch vụ đã chọn?',
  'services.informationPage': 'Chi tiết dịch vụ',
  'informationServices.ticketIndex': 'Số thứ tự',
  'informationServices.ticketStatus': 'Trạng thái',
  'informationServices.ticketStatus.id':
    '{status, select, 0 {Đang chờ} 1 {Đang thực hiện} 2 {Đã hoàn thành} 3 {Vắng} other {Không xác định}}',
  'informationServices.endDateTime': 'Thời gian sử dụng',
  'informationServices.processBy.userFullName': 'Nguời thực hiện',
  'informationServices.serviceDesk.serviceDeskName': 'Quầy dịch vụ',
  'services.activeTimes': 'Thời gian hoạt động',
  'services.times': 'Thời gian',
  'services.dayApply': 'Ngày áp dụng',
  'services.deskServices': 'Quầy dịch vụ thực hiện',
  'services.ticketStatus.waiting': 'Đang chờ',
  'services.ticketStatus.process': 'Đang thực hiện',
  'services.ticketStatus.done': 'Đã hoàn thành',
  'services.ticketStatus.cancel': 'Vắng',
  'services.ticketStatus': 'Trạng thái',
  'services.validatorServiceCode': 'Mã dịch vụ gồm 2 ký tự và không chứa khoảng trắng',
  'services.validatorServiceName': 'Tên dịch vụ không đúng định dạng',

  'services.giveNumber': 'Cấp số',
  'services.giveNumberTitle': 'Số thứ tự được cấp',
  'services.at': 'Tại',
  'services.createTime': 'Thời gian cấp',
  'services.endTime': 'Hạn sử dụng',
  'services.print': 'In',

  'services.error-title': 'Không thể cấp số',
  'services.error-text':
    'Không thể cấp số do dịch vụ không trong thời gian hoạt động. Vui lòng kiểm tra lại !',

  'services.intendTime': 'Thời gian dự kiến',
};
