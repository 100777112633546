export default {
  'ticket.name': 'Cấp số',
  'ticket.title': 'Quản lý cấp số',
  'ticket.ticketName': 'Tên dịch vụ ',
  'ticket.createDateTime': 'Thời gian cấp',
  'ticket.endDateTime': 'Thời gian sử dụng',
  'ticket.ticketStatus': 'Trạng thái',
  'ticket.processBy.userFullName': 'Người thực hiện',
  'ticket.action': 'Hành động',
  'ticket.serviceDesk.serviceDeskName': 'Quầy dịch vụ',

  'ticket.information': 'Thông tin cấp số',
  'ticket.ticketIndex': 'Số thứ tự',
  'ticket.service': 'Tên dịch vụ',
  'ticket.serviceDesk': 'Quầy dịch vụ',

  'ticket.createdAt': 'Thời gian cấp',
  'ticket.filter.status': 'Trạng thái hoạt động',
  'ticket.ticketStatus.wait': 'Đang chờ',
  'ticket.ticketStatus.done': 'Đã sử dụng',
  'ticket.ticketStatus.cancel': 'Bỏ qua',
  'ticket.ticketStatus.process': 'Đang thực hiện',
  'ticket.table.service': 'Tên dịch vụ',
  'ticket.startDay': 'Từ ngày',
  'ticket.endDay': 'Đến ngày',
  'ticket.processBy': 'Người thực hiện',

  'ticket.ticketStatus.id':
    '{status, select, 0 {Đang chờ} 1 {Đang thực hiện} 2 {Đã sử dụng} other {Bỏ qua}}',
};
