export default {
  'serviceDeskCms.name': 'Service desk',
  'serviceDeskCms.title': 'Service desks',

  'serviceDeskCms.serviceDeskCode': 'Service desk code',
  'serviceDeskCms.serviceDeskName': 'Service desk name',
  'serviceDeskCms.isActive': 'Operating status',
  'serviceDeskCms.service.serviceName': 'Usage service',
  'serviceDeskCms.createDateTime': 'Time creation',
  'serviceDeskCms.action': 'Action',

  'serviceDeskCms.create': 'Add service desk',
  'serviceDeskCms.information': 'Service desks information',
  'serviceDeskCms.update': 'Update service desk',
  'serviceDeskCms.status.active': 'Active',
  'serviceDeskCms.status.notActive': 'Not active',
  'serviceDeskCms.validator-code': 'Service desks must not leave blank',
  'serviceDeskCms.validator-name': 'The name of the service desk is not allowed to leave',
  'serviceDeskCms.serviceCode': 'Usage service',
  'serviceDeskCms.internalComment': 'Describe',
  'serviceDeskCms.delete.content': 'Do you want to delete this service?',
  'serviceDeskCms.createdAt': 'Choose time',
  'serviceDeskCms.dateFrom': 'From date',
  'serviceDeskCms.dateTo': 'To date',
  'serviceDeskCms.filter.status': 'Operating status',

  'serviceDeskCms.isActive.id': '{status, select, 1 {Active} 0 {Not active} other {--} }',
  'serviceDeskCms.modal.text': 'How to call: Please invite customers with the order number ...',
};
