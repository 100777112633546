export default {
  'users.name': 'Quản lý người dùng',
  'users.userName': 'Tên đăng nhập',
  'users.userFullName': 'Họ và tên',
  'users.userPhone': 'Số điện thoại',
  'users.action': 'Hành động',
  'users.update': 'Cập nhật người dùng',
  'users.information': 'Thông tin người dùng',
  'users.password': 'Mật khẩu',
  'users.role.roleName': 'Vai trò',
  'users.create': 'Tạo người dùng',
  'users.delete': 'Xóa người dùng',
  'user.content.delete': 'Bạn có chắc chắn muốn xóa người dùng này không?',
  'users.citizenId': 'Số CMND/CCCD',
  'users.userEmail': 'Email',
  'users.birthday': 'Ngày sinh',
  'users.address': 'Địa chỉ',
  'users.gender': 'Giới tính',
  'users.male': 'Nam',
  'users.female': 'Nữ',
  'users.other': 'Khác',
  'users.countryName': 'Tên quốc gia',
  'users.isActive': 'Trạng thái hoạt động',

  'profile.edit': 'Chỉnh sửa',
  'users.content.delete': 'Bạn có chắc chắn muốn xóa người dùng này không?',

  'users.table.username': 'Tên đăng nhập',
  'users.table.name': 'Họ và tên',
  'users.table.type': 'Loại tài khoản',
  'users.table.role': 'Vai trò',
  'users.table.phoneNumber': 'Số điện thoại',
  'users.table.email': 'Email',
  'users.table.action': 'Hành động',
  'users.table.type.id': '{status, select, 1 {Người dùng hệ thống} 2 {PG} other {--}}',

  'users.modal.form.username': 'Tên đăng nhập',
  'users.modal.form.password': 'Mật khẩu',
  'users.modal.form.password.placeholder': 'Vui lòng nhập mật khẩu',
  'users.modal.form.confirmPassWord': 'Xác nhận mật khẩu',
  'users.modal.form.confirmPassWord.placeholder': 'Vui lòng nhập xác nhận mật khẩu',
  'users.modal.form.confirmPassWord.required': 'Hai mật khẩu bạn đã nhập không khớp',
  'users.modal.form.name': 'Họ và tên',
  'users.modal.form.gender': 'Giới tính',
  'users.modal.form.gender.placeholder': 'Vui lòng nhập giới tính',
  'users.modal.form.gender.label.1': 'Nam',
  'users.modal.form.gender.label.2': 'Nữ',
  'users.modal.form.gender.label.3': 'Khác',
  'users.modal.form.dob': 'Ngày sinh',
  'users.modal.form.avatar': 'Hình đại diện',
  'users.modal.form.roleId': 'Vai trò',
  'users.modal.form.phoneNumber': 'Số điện thoại',
  'users.phoneNumber.notMatch': 'Số điện thoại không hợp lệ',
  'users.modal.form.email': 'Email',
  'users.modal.form.citizenId': 'CMND/CCCD',
  'users.modal.form.countryName': 'Quốc gia',
  'users.modal.form.address': 'Địa chỉ',
  'users.modal.form.type': 'Loại tài khoản',
  'users.modal.form.type.placeholder': 'Vui lòng nhập loại tài khoản',
  'users.modal.form.type.label.1': 'Người dùng hệ thống',
  'users.modal.form.type.label.2': 'PG',

  /////// User
  'user.information': 'Thông tin người dùng',
  'user.update': 'Chỉnh sửa người dùng',
  'user.create': 'Thêm người dùng mới',

  'user.name': 'Người dùng',
  'user.list': 'Danh sách người dùng',
  'user.userName': 'Tên đăng nhập',
  'user.userPassword': 'Mật khẩu',
  'user.userFullName': 'Họ và tên',
  'user.gender': 'Giới tính',
  'user.male': 'Nam',
  'user.female': 'Nữ',
  'user.dayOfBirth': 'Ngày sinh',
  'user.role': 'Vai trò',
  'user.system': 'Hệ thống bán hàng',
  'user.userPhone': 'Số điện thoại',
  'user.userEmail': 'Email',
  'user.idCard': 'CMND/CCCD',
  'user.national': 'Quốc gia',
  'user.address': 'Địa chỉ',
  'user.delete-content': 'Bạn có muốn xóa người dùng đã chọn?',
  'user.delete-title': 'Xóa người dùng',
  'user.action': 'Hành động',
  'user.userAvatar': 'Hình đại diện',
  'user.isActive': 'Trạng thái hoạt động',
  'user.status.active': 'Hoạt động',
  'user.status.notActive': 'Ngưng hoạt động',
  'user.delete': 'Bạn có muốn xóa người dùng này?',
  'user.validator-userName': 'Tên đăng nhập không được để trống',
  'user.validator-userFullName': 'Họ và tên không được để trống',

  'users.isActive.id': '{status, select, 1 {Hoạt động} 0 {Ngưng hoạt động} other {--} }',
  'users.validate-password': 'Mật khẩu không đúng định dạng',
  'users.validate-phone': 'Số điện thoại không đúng định dạng',
};
