import React from 'react';
import { MessageSquare } from 'react-feather';

import { IRouter } from '@routers/interface';

const routerEvaluate: IRouter = {
  path: '/evaluate',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'evaluate.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <MessageSquare />,
  },
};

export default routerEvaluate;